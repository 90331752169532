.containerSearch {
    position: relative;
    --size-button: 40px;
  }
  
  .inputSearch {
    padding-left: var(--size-button);
    height: var(--size-button);
    font-size: 16px;
    font-weight: 500;
    border: none;
    color: #fff;
    outline: var(--size-button);
    width: 40px;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    background-color: #00a1a9;
    border-radius: 50px;
    cursor: pointer;
  }

  .inputSearch:focus,
  .inputSearch:not(:invalid) {
    width: 200px;
    cursor: text;
  }
  
  .inputSearch:focus + .icon,
  .inputSearch:not(:invalid) + .icon {
    pointer-events: all;
    cursor: pointer;
  }
  
  .containerSearch .icon {
    position: absolute;
    width: var(--size-button);
    height: var(--size-button);
    top: 0;
    left: 0;
    padding: 8px;
    pointer-events: none;
  }
  
  .containerSearch .icon svg {
    width: 100%;
    height: 100%;
  }