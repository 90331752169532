@import url("http://fonts.googleapis.com/css?family=roboto");

body {
  font-family: "Roboto", sans-serif;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  max-width: 100%;
}
* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}
