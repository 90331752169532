@import url(http://fonts.googleapis.com/css?family=roboto);
.style_containerSearch__1HGfP {
    position: relative;
    --size-button: 40px;
  }
  
  .style_inputSearch__3zqss {
    padding-left: var(--size-button);
    height: var(--size-button);
    font-size: 16px;
    font-weight: 500;
    border: none;
    color: #fff;
    outline: var(--size-button);
    width: 40px;
    transition: all ease 0.3s;
    background-color: #00a1a9;
    border-radius: 50px;
    cursor: pointer;
  }

  .style_inputSearch__3zqss:focus,
  .style_inputSearch__3zqss:not(:invalid) {
    width: 200px;
    cursor: text;
  }
  
  .style_inputSearch__3zqss:focus + .style_icon__1vqvM,
  .style_inputSearch__3zqss:not(:invalid) + .style_icon__1vqvM {
    pointer-events: all;
    cursor: pointer;
  }
  
  .style_containerSearch__1HGfP .style_icon__1vqvM {
    position: absolute;
    width: var(--size-button);
    height: var(--size-button);
    top: 0;
    left: 0;
    padding: 8px;
    pointer-events: none;
  }
  
  .style_containerSearch__1HGfP .style_icon__1vqvM svg {
    width: 100%;
    height: 100%;
  }
body {
  font-family: "Roboto", sans-serif;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  max-width: 100%;
}
* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

